import {
  VStack,
  ListItem,
  useColorModeValue,
  Heading,
  Link,
  Image, Divider,
  Text,
  Flex,
} from '@chakra-ui/react';
import { Simple_ES } from '../../components/spa/NavBarHeader_ES.tsx';
import '../../fonts/Fashion-Fetish-Bold.ttf';
import '../../fonts/Fashion-Fetish-Heavy.ttf';
import '../../fonts/Fashion-Fetish-Italic.ttf';
import '../../fonts/Fashion-Fetish-Light-Italic.ttf';
import '../../fonts/Fashion-Fetish-Outline.ttf';
import '../../fonts/Fashion-Fetish-Regular.ttf';
import '../../fonts/Fashion-Fetish-Small-Caps.ttf';
import { Footer } from '../../components/spa/Footer.tsx';
import image1 from '../../components/images/contacto.jpg'

export const Contacto = () => {
  return (<>
    <Simple_ES />
    <div className='background-wrapper'>
      <Text
        bgColor={'whiteAlpha.800'}
        textColor='#F26D35'
        textAlign={'center'}
        fontSize={'xl'}
        fontFamily={'Fashion-Fetish-Light'}
      >𝗦𝗨 𝗧𝗥𝗔𝗡𝗤𝗨𝗜𝗟𝗜𝗗𝗔𝗗 𝗘𝗦 𝗡𝗨𝗘𝗦𝗧𝗥𝗔 𝗠𝗔𝗬𝗢𝗥 𝗣𝗥𝗘𝗢𝗖𝗨𝗣𝗔𝗖𝗜𝗢𝗡</Text>
      <Flex
        w='full'
        bgColor={useColorModeValue('whiteAlpha.800', 'whiteAlpha.800')}
      >

        {/* <CaptionCarousel/> */}
        <div className='back-image'>
          <Image src={image1} />

        </div>

      </Flex>
      <Divider
        borderRadius={'1px'}
        margin='2'
        bgColor={'black'}
      ></Divider>
      <Text
        p='2'
        color='#F26D35'
        textAlign={'center'}
        fontSize={'xl'}
        fontFamily={'Fashion-Fetish-Light'}>
        <Heading fontFamily={'Fashion-Fetish-Bold'} color={"whiteAlpha.900"} size="lg" ><Link href='mailto:contacto@conmatsecurity.net'>Envíanos un mail </Link></Heading>
        <Divider
          borderRadius={'1px'}
          margin='2'
          bgColor={'black'}
        ></Divider>
        <Heading fontFamily={'Fashion-Fetish-Bold'} color={"whiteAlpha.900"} size="lg" ><Link href='https://wa.me/5491171447430'>Escribenos a nuestro Whatsapp </Link></Heading></Text>
      <Divider
        borderRadius={'1px'}
        margin='2'
        bgColor={'black'}
      ></Divider>

      <Flex
        bgColor={useColorModeValue('blackAlpha.800', 'blackAlpha.800')}
        justify={'center'}
      >
      </Flex>
      <Footer />
    </div>

    {/* </div> */}
  </>
  );
}
